import React from "react"
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
  faLinkedin,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Icon {
  color: string
  link: string
  icon: IconDefinition
}

const icons: Icon[] = [
  {
    icon: faFacebookSquare,
    color: "#3b5999",
    link: "https://www.facebook.com/FarmaceuticaYounger/",
  },
  {
    icon: faInstagram,
    color: "#e4405f",
    link: "https://www.instagram.com/farmaceutica_younger/",
  },
  {
    icon: faLinkedin,
    color: "#0077B5",
    link: "https://www.linkedin.com/company/farmaceutica-younger",
  },
  {
    icon: faYoutube,
    color: "#cd201f",
    link: "https://www.youtube.com/channel/UCBzcNd6Z480lWkchyanC4_A",
  },
]

const SocialIcon = (props: Icon) => {
  return (
    <a href={props.link} target="_blank">
      <FontAwesomeIcon
        size="2x"
        icon={props.icon}
        style={{ color: props.color }}
      />
    </a>
  )
}

export const Footer = () => {
  return (
    <footer className="p-10 text-center">
      <div className="mt-4">
        <p> Farmaceutica Younger © {new Date().getFullYear()} </p>
      </div>
      <div className="mt-4">
        <p>Seguici sui principali social network</p>
      </div>

      <div className="mt-4 flex justify-center">
        {icons.map((icon, id) => (
          <div className="mx-2">
            <SocialIcon {...icon} key={id}></SocialIcon>
          </div>
        ))}
      </div>
    </footer>
  )
}

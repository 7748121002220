import { Link } from "gatsby"
import React from "react"

const links = [
  {
    to: "/",
    name: "Home",
  },
  {
    to: "/statuto",
    name: "Statuto",
  },
]

export const Header = ({ siteTitle }) => (
  <header className="p-4">
    <div className="flex">
      <Link to="/" className="font-thin text-lg">
        Associazione
      </Link>
      <span className="flex-grow"></span>
      {links.map((link, idx) => (
        <Link
          key={idx}
          to={link.to}
          className="font-semibold ml-4"
          activeClassName="border-primary-500 border-b-8"
        >
          {link.name}
        </Link>
      ))}
    </div>
  </header>
)
